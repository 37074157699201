/* player Styles */
.player--main--wrapper {
  margin-right: 2px;
  position: relative;
}

.player--main {
  display: flex;
}

.player--main--list-view {
  display: flex;
}

.player--main--clue-bar {
  background-color: var(--main-blue-3);
  padding: 10px 20px 10px 20px;
  display: flex;
  font-size: 15px;
  /* width: 486px; */
  height: 40px;
  align-items: stretch;
}

.player--main--clue-bar--number {
  font-weight: bold;
  display: inline-block;
  align-self: center;
}

.player--main--clue-bar--text--wrapper {
  flex: 1;
  position: relative;
  margin-left: 20px;
  max-height: 100%;
}

.player--main--clue-bar--text {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: flex-begin;
}

.player--main--clue-bar--text span {
}

.player--main--left {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.player--main--left--grid {
  position: relative;
  color: #444;
}

.player--main--left--grid.frozen {
  margin-top: 10px;
  position: relative;
  color: black;
}

.player--main--clues {
  display: flex;
}

.player--main--vim-bar {
  background-color: var(--main-blue-3);
  padding: 10px 10px 10px 10px;
  display: flex;
  font-size: 15px;
  /* width: 486px; */
  height: 20px;
  align-items: stretch;
}

.player--main--vim-bar--input {
  background-color: transparent;
  border: none;
}

.player--main--vim-bar--input:focus {
  outline: none;
}

.player--mobile--wrapper {
  display: flex;
  flex: 1;
}

.player--mobile {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.player--mobile--grid {
  display: flex;
}

.player--mobile--list-view {
  display: flex;
}

.player--mobile--clue-bar {
  background-color: var(--main-blue-3);
  padding: 10px 20px 10px 20px;
  display: flex;
  font-size: 15px;
  flex: 1;
  min-height: 20px;
  align-items: center;
}

.player--mobile--clue-bar .player--mobile--typing-hint {
  display: none;
}
.player--mobile--clue-bar:nth-child(3) .player--mobile--clue-bar--text {
  display: none;
}
.player--mobile--clue-bar:nth-child(3) .player--mobile--clue-bar--number {
  display: none;
}
.typing .player--mobile--clue-bar:nth-child(3) .player--mobile--clue-bar--number {
  display: block;
}
.typing .player--mobile--clue-bar:nth-child(3) .player--mobile--clue-bar--text {
  display: block;
}
.typing .player--mobile--clue-bar:nth-child(3) .player--mobile--typing-hint {
  display: none;
}

.player--mobile--typing-hint {
  width: 100%;
  font-size: 30pt;
  text-align: center;
}

.player--mobile--clue-bar:nth-child(3) .player--mobile--typing-hint {
  display: block;
}

.player--mobile--clue-bar--number {
  font-weight: bold;
  display: inline-block;
}

.player--mobile--clue-bar--text {
  margin-left: 20px;
  display: inline-block;
  overflow-y: auto;
  max-height: 100%;
}
