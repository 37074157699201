.replays {
  font-family: 'avenir next', avenir, 'Nunito', 'helvetica neue', 'Helvetica', 'arial', sans-serif;
  height: 100%;
  width: 100%;
  min-width: min-content;
  position: relative;
}

.replays .grid-controls .grid--content .blurable {
  opacity: 1;
}

.replays .grid-controls .grid--cover {
  display: none;
}

.replays .scrub--container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 10px;
  margin-bottom: 17.8px;
}

.replays .scrub {
  cursor: pointer;
  user-select: none;
}

.replays .limit--container {
  margin: 35px 0 0 0;
}

.replays .limit--text {
  margin-right: 10px;
}

.replays .limit--button {
  margin: 0 5px 0 5px;
  text-align: left;
  border-radius: 3px;
  padding: 8px 15px 8px 15px;
  border-top: 1px solid var(--main-gray-2);
  border-left: 1px solid var(--main-gray-2);
  border-bottom: 1px solid var(--main-gray-2);
  border-right: 1px solid var(--main-gray-2);
  cursor: pointer;
}

.replays .limit--button:hover {
  transition: all ease-in 0.2s;
  color: #111;
  background-color: #dddddd;
}

.replays .scrub.active {
  color: #4d4dff;
  font-weight: bold;
}

.replays .header--title {
  font-size: 30px;
  font-weight: 500;
  color: #333;
  text-decoration: none;
}

.replays .header--subtitle {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
}

.replays .main-table {
  table-layout: auto;
  border-collapse: collapse;
  width: 100%;
}

.replays .main-table tr {
  text-align: center;
}

.replays .main-table tr:hover {
  background-color: var(--main-blue-3);
}

.replays .main-table td {
  border-bottom: 1px solid #e2e2e2;
  padding: 12px 0 12px 0;
}

.replays .main-table td a {
  text-decoration: none;
}

.replays .main-table td a:hover {
  text-decoration: underline;
}
