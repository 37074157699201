.editable-span {
}

.editable-span:hover {
  background-color: rgba(255, 255, 255, 0.7);
}

.editable-span:focus {
  background-color: rgba(255, 255, 255, 0.99);
  outline: none;
}

.editable-span--caret::after {
  content: '|';
}
