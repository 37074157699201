.grid-controls {
  outline: none;
  position: relative;
}

.grid-controls:focus-within .grid--content .blurable {
  opacity: 1;
}

.grid-controls .grid--content .blurable {
  opacity: 0.9;
}

.grid--cover {
  z-index: 3;
  opacity: 1;
  position: absolute;
  bottom: 3px;
  left: 0;
  padding: 10px 10px 0px 10px;
  color: #444;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
}

.grid-controls:focus .grid--cover {
  display: none;
  /* just to be safe */
  z-index: -3;
  font-size: 1px;

  opacity: 0;
}
