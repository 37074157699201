.cell {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* z-index: -1; */
  transition: background-color 0.12s ease;
  display: flex;
  flex-direction: column;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  background-color: white;
}

.cell--wrapper {
  position: relative;
  border: 2px solid transparent;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.cell {
  box-sizing: border-box;
}

.cell.black {
  /* z-index: 2; */
  background-color: var(--main-black);
  color: var(--main-black);
}

.cell.hidden {
  pointer-events: none;
  background-color: var(--main-gray-2);
  color: var(--main-gray-2);
}

.cell.black.selected {
  background-color: var(--main-black);
  color: var(--main-black);
  border: 3px solid #4aeba1;
}

.frozen .cell.highlighted {
  background-color: var(--main-green-1);
}

div:focus .frozen .cell.highlighted {
  background-color: var(--main-green-2);
}

.cell.highlighted {
  background-color: var(--main-blue-3);
}

div:focus .cell.highlighted {
  background-color: var(--main-blue-2);
}

.cell.referenced {
  background-color: #b3ff00;
}

div:focus .cell.referenced {
  background-color: #b3ff00; /* TODO */
}

.cell.referenced.highlighted {
  /* TODO */
}

.cell.selected {
  background-color: #4aeba1;
  /*background-color: #98ee99;*/
}

.cell--value {
  background-color: transparent;
}

.cell.pencil .cell--value {
  color: var(--pencil-color);
}

.cell.bad .cell--value {
  color: red;
}

.cell.good .cell--value {
  color: blue;
}

.cell.revealed .cell--value {
  color: green;
}

.cell--number {
  position: absolute;
  top: 2px;
  left: 2px;
  font-size: 150%;
  margin-right: auto;
  flex: 3;
  line-height: 98%;
  height: 5px;
  background-color: transparent;
  z-index: 11;
}

.cell--number.nonempty {
  background-color: inherit;
}

.cell--value {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  vertical-align: middle;
  font-size: 350%;
  z-index: 11;
}

.tiny .cell--number {
  top: 0px;
  left: 0px;
}

.small .cell--number {
  top: 1px;
  left: 1px;
}

.cell--cursors {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2; /* may go up to 9*/
}

.cell--cursor {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-style: solid;
  /* The below styles will be overwritten in code */
  border-color: blue;
  border-width: 1px;
}

.cell--ping {
  border-radius: 100%;
  width: 100%;
  height: 100%;
  position: absolute;
  border-style: solid;
  border-width: 3px;
  box-sizing: border-box;
  left: 0;
  top: 0;
  animation: ping 3s ease-in;
  opacity: 0;
  transform-origin: 50% 50%;
}

@keyframes ping {
  from {
    transform: scale(0.2);
    opacity: 1;
  }
  to {
    transform: scale(3);
    opacity: 0;
  }
}

.cell--cursor.inactive {
  opacity: 0.2;
}

.cell--circle {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 1px solid var(--main-gray-1);
  border-radius: 50%;
  z-index: 10;
}

.cell--shade {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 10;
}
