.upload {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.upload--main {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  /* width: 150px; [> TODO: remove this non-flex layout <] */
}

.upload--main--upload {
  display: flex;
  flex-direction: column;
  /* flex-basis: 150px; */
}

.upload--main--upload--title {
  font-size: 22px;
  text-align: center;
}

.upload--go {
  margin-top: 25px;
  margin-left: 25px;
  margin-right: 25px;
  font-size: 18px;
}

.upload--preview {
  height: 40px;
  padding: 15px 15px 15px 15px;
}

.upload--success {
  margin-top: 10px;
  font-size: 12px;
  text-align: center;
}

.upload--success--title {
  font-weight: bold;
}

.upload--button {
  margin-top: 12px;
  font-size: 15px;
  background-color: #fafafa;
}

.upload--button.v2 {
  background-color: #ffffff;
}
