.list-view {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  padding-right: 10px;
}

.list-view--scroll {
  position: relative;
  flex-basis: calc(80vh - 48px);
  flex-grow: 1;
  overflow-y: auto;
  border-top: 1px solid #e2e2e2;
  margin-top: 5px;
  padding-right: 10px;
}

.list-view--list {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  padding-right: 10px;
}

.list-view--list--title {
  font-weight: bold;
  font-size: 14px;
  position: sticky;
  top: 0;
  background: #fff;
  padding-top: 5px;
  padding-bottom: 5px;
  z-index: 12;
}

.list-view--list--clue {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 5px;
  padding-bottom: 5px;
  border-left: 5px solid transparent;
  border-bottom: 1px solid #e2e2e2;
  cursor: pointer;
}

.list-view--list--clue--number {
  font-weight: 400;
  font-weight: bold;
}

.list-view--list--clue--text {
  margin-left: 10px;
  padding-bottom: 5px;
}

.list-view--list--clue--break {
  flex-basis: 100%;
  height: 0;
}

.list-view--list--clue-grid {
  display: flex;
}
