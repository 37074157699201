.hints {
  display: flex;
  flex-direction: column;
}

.hints--pattern {
  border: 1px solid black;
  padding: 20px;
  cursor: pointer;
}

.hints--matches {
  border: 1px solid black;
  padding: 20px;
}

.hints--matches--entries {
  overflow-y: auto;
  max-height: 200px;
}

.hints--matches--entry {
  padding: 10px;
}

.hints--matches--entry:nth-child(odd) {
  background-color: var(--main-gray-2);
  color: #000;
}

.hints--matches--entry:nth-child(even) {
  background-color: #fff;
  color: #333;
}
