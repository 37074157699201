.welcome {
  background-color: #ffffff;
  color: var(--main-gray-1);
}

.welcome--browse {
  flex: 2;
  padding: 15px 15px 15px 15px;
  display: flex;
  flex-direction: row;
}

.welcome--browse--puzzlelist--wrapper {
  flex: 6;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.welcome--browse--puzzlelist {
  padding: 10px 0px 10px 0px;
  width: 50%;
}

.welcome--browse--title {
  text-align: center;
  font-size: 22px;
}

.welcome--main {
  /* padding: 10px; */
  overflow: hidden;
}

.welcome--sidebar {
  padding-bottom: 40px;
  border-right: 2px solid var(--main-blue);
}

.welcome--sidebar > div {
  padding-left: 5px;
  padding-right: 5px;
}

.welcome--searchbar--container {
  padding: 25px;
  border-bottom: 2px solid var(--main-blue);
}

.welcome.mobile .welcome--searchbar--container {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: var(--main-blue);
  position: relative;
}

.welcome.mobile .welcome--searchbar {
  color: var(--main-blue);
  width: 1px;
  flex-grow: 1;
  border: none;
}

.welcome--searchbar {
  font-size: 16px;
  padding-right: 5px;
  padding-left: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  border-radius: 3px;
  border: 1px solid silver;
}

.welcome--searchbar--wrapper {
  position: relative;
}

.welcome--searchicon {
  color: var(--main-blue);
  height: 24px;
  width: 24px;
  padding: 8px;
  position: absolute;
}

.filters label {
  position: relative;
  cursor: pointer;
  line-height: 24px;
  margin: 4px 0;
}

.checkbox-icon {
  float: left;
  width: 24px;
  height: 24px;
  fill: #6aa9f4;
  margin-right: 8px;
}

.filters input {
  width: 24px;
  height: 24px;
  position: absolute;
  opacity: 0;
}

.quickplay {
  padding-top: 10px;
  border-top: 2px solid #6aa9f4;
}

.entry {
  cursor: pointer;
  white-space: nowrap;
  position: relative;
  border: 1px solid silver;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 25px;
  font-size: 15px;
  width: 330px;
}

.mobile .entry {
  width: 80vw;
}

.entry--top--left {
  font-size: 15px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.7);
  justify-content: space-between;
}

.entry--main {
  padding-top: 10px;
}

.entry--details {
  padding-top: 10px;
}

.entry--details > p {
  font-size: 0.75em;
}

.entry--icon {
  position: relative;
  z-index: 1;
  bottom: 15px;
  left: 15px;
}

.entry.started {
  border-color: #a0d9fc;
}

.entry.solved {
  border-color: #8df79d;
}

@keyframes slidein {
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
}

.entry.new {
  animation: 1s slidein;
}

.entry--back {
  opacity: 0;
  transition: opacity 0.2s ease-in;
  -moz-user-select: none;
}

.entry.flipped .entry--back {
  opacity: 1;
  transition: opacity 0.2s ease-in;
}

.entry--front {
  flex: 1;
  height: 50px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}

.entry--front--title {
  font-size: 15px;
  text-align: center;
}

.entry--front--author {
  margin-top: 8px;
  font-size: 12px;
  text-align: center;
}

.entry--back {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(40, 69, 94, 0.95);
}

.entry--back--btns {
  display: flex;
  justify-content: space-around;
}

.entry--back--btn {
  padding: 10px;
  font-size: 15px;
  font-weight: bold;
  color: white;
  background-color: rgba(106, 169, 254, 0.95);
  border-radius: 5px;
}

.entry--back--btn:hover {
  color: white;
  background-color: rgb(76, 109, 184);
}

.entry--back--btn.play-solo {
}

::-webkit-scrollbar {
  width: 2px; /* remove scrollbar space */
  background: transparent; /* optional: just make scrollbar invisible */
}
/* optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: var(--main-gray-3);
}

input::placeholder {
  color: silver;
  font-weight: 200;
}

p {
  margin-top: unset;
  margin-bottom: unset;
}
