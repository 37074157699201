/* chat style */
.chat {
  flex-grow: 1;
  flex-shrink: 1;
  padding-top: 10px;
  padding-left: 25px;
  padding-right: 25px;
  border-left: 1px solid #e2e2e2;
  min-width: 300px;
  display: flex;
  flex-direction: column;
}

.chat.mobile {
  padding: 0px;
}

.chat--header {
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 5px;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: space-between;
}

.chat--header--title {
  font-size: 20px;
  font-weight: 500;
  color: #333;
  text-decoration: none;
}

.chat--header--subtitle {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
}

.chat--header--description {
  margin-top: 5px;
  margin-bottom: 2px;
  font-size: 12px;
  font-weight: 500;
  font-style: italic;
}

.chat--username {
  padding: 5px;
  padding-top: 10px;
}

.chat--username--input {
  display: inline;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 16px;
}

.chat--username--input.editable-span:hover {
  background-color: #f8f8654d;
  cursor: pointer;
}

.chat--username--input.editable-span:focus {
  background-color: #f8f8654d;
  cursor: initial;
}

.chat--users--present {
  padding: 5px;
  margin-bottom: 5px;
}

.chat--users--present > span {
  margin-left: 5px;
  margin-right: 8px;
}

.chat--users--present .dot {
  padding-right: 5px;
}

.chat--messages {
  /* flex: 1; */
  overflow: auto;
  /* width: 100%; */
  /* max-height: 550px; */
  min-height: 1px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 1px;
  padding-bottom: 20px;
  border-top: 1px solid #e2e2e2;
}

.chat--message {
  display: inline-flex;
  font-size: 15px;
  padding-left: 20px;
  overflow-wrap: anywhere;
  align-items: bottom;
  width: calc(100% - 20px);
}

.chat--system-message {
  color: var(--main-gray-1);
  margin: 5px;
}

.chat--message--timestamp {
  white-space: nowrap;
  margin-left: 10px;
  color: var(--main-gray-2);
  /* background-color: var(--main-gray-1); */
}

.chat--message--timestamp::selection {
  color: var(--main-gray-1);
  background-color: highlight;
}

.chat--message:hover .chat--message--timestamp {
  color: var(--main-gray-1);
}

.chat--message--content {
  flex: 1;
}

.chat--message--sender {
  font-weight: bold;
  white-space: nowrap;
  margin-left: -15px;
}

.chat--message--text {
  margin-left: 5px;
}

.big .chat--message--text {
  font-size: 200%;
}

.chat--bar {
  width: 100%;
  padding: 20px 0px 20px 0px;
  position: relative;
}

.chat--bar--input {
  width: 100%;
}

.chat--bar--input--mobile {
  width: 100%;
  border: 1px solid #dddddd;
  height: 24px;
  font-size: 14px;
}

.copyButton {
  padding-left: 5px;
  transform: scale(0.85, 1);
}

.copyButton:hover {
  cursor: pointer;
  -webkit-filter: brightness(70%);
  filter: brightness(70%);
}

.copyText:hover {
  cursor: pointer;
}

.flashBlue {
  animation: flash-blue-keyframes 0.5s;
}
@keyframes flash-blue-keyframes {
  0% {
    color: var(--main-blue);
  }
  100% {
    color: var(--main-gray-1);
  }
}
