.action-menu {
  color: #666;
  font-size: 14px;
}

.action-menu--button {
  background-color: white;
  color: #111;
}

.action-menu--list {
  position: absolute;
  top: 100%;
  background-color: var(--main-gray-3);
  display: none;
  z-index: 13;
}

.active .action-menu--list {
  display: block;
}

.action-menu--list--action:first-child {
  border-top: 1px solid var(--main-gray-2);
}

.action-menu--list--action {
  text-align: left;
  padding: 16px;
  border-left: 1px solid var(--main-gray-2);
  border-bottom: 1px solid var(--main-gray-2);
  border-right: 1px solid var(--main-gray-2);
  cursor: pointer;
}

.action-menu--list--action:hover {
  color: #111;
  background-color: #dddddd;
}

.toolbar--mobile--top .action-menu--list--action {
  box-sizing: border-box;
  height: 48px;
}
