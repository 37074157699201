.nav {
  padding: 20px 30px 20px 25px;
  min-height: 17px;
  display: flex;
  height: max-content;
  align-content: center;
  justify-content: space-between;
  background-color: var(--main-blue);
}
.nav.mobile {
  padding-top: 10px;
  padding-bottom: 10px;
}

.nav--left {
  font-size: 17px;
  font-weight: bold;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.nav.mobile .nav--left {
  font-weight: 600;
}

.nav--right div a {
  color: white;
  margin-left: 40px;
}

.nav--right {
  margin-right: 10px;
  color: white;
  text-decoration: none;
  font-size: 13px;
  display: flex;
  align-items: center;
}

.nav.mobile .nav--right {
  margin-right: 0px;
}

.nav--login {
  cursor: pointer;
  margin-left: 20px;
}

.nav--left a {
  color: white;
  text-decoration: none;
  font-size: 17px;
}

.nav--info {
  cursor: pointer;
  margin-left: 20px;
}

.nav--v2 {
  color: pink;
  margin-left: 2px;
}

.secret {
  opacity: 0;
  transition: all ease-in 0.5s;
}

.secret:hover {
  opacity: 1;
  transition: all ease-in 2s;
}

.info--hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
