.popup-menu {
  /* font-size: 14px; */
}

.popup-menu table {
  border-collapse: collapse;
}

.popup-menu--content tr:nth-child(even) {
  background-color: #ffffff;
}

.popup-menu table > th > td {
  border: 1px solid black;
  padding: 10px;
}

.popup-menu--button:hover {
  background-color: white;
  color: #000;
}

.popup-menu--content {
  position: absolute;
  font-size: 14px;
  top: 100%;
  background-color: var(--main-gray-3);
  padding: 20px;
  display: none;
  z-index: 12;
  width: 40%;
  color: #000;
}

.popup-menu--content ul {
  padding-left: inherit;
}

.popup-menu--content li {
  padding-bottom: 15px;
}

.popup-menu--content li:last-child {
  padding-bottom: 0px;
}

.active .popup-menu--content {
  display: block;
  cursor: auto;
}

.popup-menu--content--popup:first-child {
  border-top: 1px solid var(--main-gray-2);
}

.popup-menu--content--popup {
  text-align: left;
  padding: 15px;
  border-left: 1px solid var(--main-gray-2);
  border-bottom: 1px solid var(--main-gray-2);
  border-right: 1px solid var(--main-gray-2);
}

.toolbar--mobile--top .popup-menu--list--popup {
  padding: 5px;
}
