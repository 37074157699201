.entry--container {
  margin-left: 25px;
  margin-top: 25px;
}

.entry--icon {
  fill: #6aa9f4;
}
.entry--icon.fencing {
  fill: #353d46;
}

.mobile .entry--container {
  margin: 18px auto 0;
}

.mobile .entry {
  margin: 0;
}
