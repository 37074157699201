.powerups--main {
  background-color: #f3f3f3;
  display: flex;
  margin-top: 10px;
  margin-bottom: 30px;
}

.powerups--header {
  justify-content: center;
  color: #c87e25;
  font-weight: bold;

  writing-mode: vertical-lr;
}

.powerups--label {
  justify-content: center;
  color: #f8bd2a;
  font-weight: bold;
}

.powerups--info {
  margin-top: 5px;
  font-size: 70%;
  text-align: center;
  color: #c87e25;
}

.powerups--powerup {
  justify-self: center;
  margin-left: 20px;
  margin-right: 20px;

  flex-direction: column;
}

.powerups--unused {
  cursor: pointer;
}

.powerups--emoji {
  position: relative;
}

.powerups--count {
  position: absolute;
  background-color: blue;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  text-align: center;
  top: 0px;
  right: 0px;
  vertical-align: middle;
  color: white;
}

span {
  justify-content: center;
}

.powerups--unused span img {
  border-radius: 50%;
  padding: 2px;
  padding-bottom: 2.2px;
  padding-right: 2.1px;
  border: 2px solid #59677e !important;
  box-sizing: border-box;
}

.powerups--in-use span img {
  border-radius: 50%;
  padding-bottom: 0.2px;
  padding-right: 0.1px;
  border: 10px solid #339966 !important;
  box-sizing: border-box;
}
