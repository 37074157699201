code {
  font-family: monospace;
  background-color: rgb(223, 223, 223);
}

.toolbar {
  font-size: 16pt;
  padding: 15px 10px 13px 50px;
  min-height: 20px;
  border-top: 1px solid #e2e2e2;
  border-left: 1px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  position: relative;
  text-align: center;
}

.toolbar button {
  color: var(--main-gray-1);
  padding: 7px 10px 7px 10px;
  border-radius: 5px;
  background-color: white;
  border: none;
  outline: none;
  cursor: pointer;
}

.toolbar button:hover {
  background-color: var(--main-gray-3);
}

.toolbar--timer {
  text-align: center;
}

.toolbar--color-attribution-toggle, .toolbar--list-view, .toolbar--pencil, .toolbar--autocheck {
  cursor: pointer;
}

.toolbar--pencil {
  display: flex;
}

.toolbar--pencil-color-picker-container {
  width: 14px;
  height: 14px;
  margin-left: 3px;
}

.toolbar--pencil-color-picker {
  cursor: pointer;
  background-color: var(--pencil-color);
  width: 14px;
  height: 14px;
  position: absolute;
  z-index: 10;
  border-radius: 14px;
  border: 1px solid black;
}

.toolbar--pencil-color-picker-container input {
  position: absolute;
  pointer-events: none;
  opacity: 0;
  width: 14px;
  height: 14px;
}

.toolbar--replay-link {
  width: 24px;
  height: 24px;
  color: inherit;
  display:flex;
  justify-content: center;
  align-items: center;
}

.toolbar--info {
  cursor: pointer;
}

.toolbar--pencil.on .fa-pencil {
  color: var(--main-blue) !important;
}

.toolbar--color-attribution-toggle.on {
  color: var(--main-blue) !important;
}

.toolbar--list-view.on .fa-list {
  color: var(--main-blue) !important;
}

.toolbar--autocheck.on .fa-check-square {
  color: var(--main-blue) !important;
}

.toolbar--mobile .toolbar--autocheck {
  color: var(--main-gray-2);
}
.toolbar--mobile .toolbar--autocheck.on .fa-check-square {
  color: white !important;
}

.toolbar--mobile {
  height: 48px;
  background-color: var(--main-blue);
  justify-content: space-between;
  font-size: 14pt;
  color: white;
}

.toolbar--mobile a:first-child {
  color: white;
  margin-left: 16px;
  text-decoration: none;
  font-weight: 600;
}

.toolbar--mobile button {
  background-color: transparent;
  border: none;
  padding: 16px;
  color: white;
  font-size: 14px;
  height: 48px;
}

.toolbar--mobile svg {
  font-size: 18pt;
}

.toolbar--mobile--top {
  justify-content: space-between;
  align-items: center;
  position: relative;
  text-align: center;
}

.toolbar--mobile .clock {
  color: white;
}

.toolbar--mobile .toolbar--expand:last-child {
  margin-right: 16px;
}
