/* editor Styles */
.editor--main {
  margin-top: 20px;
  display: flex;
}

.editor--main--clue-bar {
  background-color: var(--main-blue-3);
  padding: 10px 20px 10px 20px;
  display: flex;
  font-size: 15px;
  height: 70px;
  align-items: center;
}

.editor--main--clue-bar {
  opacity: 2;
}

.editor--main--clue-bar--number {
  font-weight: bold;
  display: inline-block;
}

.editor--main--clue-bar--text {
  display: inline-block;
  overflow: auto;
  max-width: 380px;
  margin-left: 15px;
  align-items: center;
}

.editor--main--clue-bar--text .editable-span {
  padding: 5px;
}

.editor--main--left {
  display: flex;
  flex-direction: column;
  align-items: auto;
}

.editor--main--left--grid {
  margin-top: 10px;
  position: relative;
  border: 4px solid black;
  color: #444;
}

.editor--right {
  display: flex;
  /* justify-content: space-between; */
  flex-direction: column;
}

.editor--main--clues {
  flex: 1;
  display: flex;
  padding: 0px 20px 0px 20px;
}

.editor--main--clues--list {
  width: 200px;
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  padding-right: 10px;
}

.editor--main--clues--list--title {
  font-weight: bold;
  font-size: 14px;
}

.editor--main--clues--list--scroll {
  position: relative;
  overflow-y: auto;
  border-top: 1px solid #e2e2e2;
  margin-top: 5px;
  padding-right: 10px;
}

.editor--main--clues--list--scroll--clue {
  display: flex;
  flex-direction: row;
  padding-top: 5px;
  padding-bottom: 5px;
  border-left: 5px solid transparent;
  cursor: pointer;
}

.editor--main--clues--list--scroll--clue.complete {
  color: #888;
}

.editor--main--clues--list--scroll--clue.selected {
  background-color: var(--main-blue-3);
}

.editor--main--clues--list--scroll--clue.half-selected {
  border-left: 5px solid var(--main-blue-3);
}

.editor--main--clues--list--scroll--clue--number {
  margin-left: 5px;
  font-weight: 400;
  line-height: 19px;
  font-weight: bold;
}

.editor--main--clues--list--scroll--clue--text {
  margin-left: 15px;
}

.editor--button {
  cursor: pointer;
  border-radius: 5px;
  padding: 10px;
  border: 1px solid var(--main-blue);
  font-weight: bold;
  color: var(--main-blue);
  margin-top: 30px;
  justify-self: center;
}

.editor--right--hints {
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.editor--grid-size {
  padding: 10px;
  font-weight: bold;
  color: var(--main-blue);
  margin-top: 30px;
  justify-content: left;
}
