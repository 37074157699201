.compose {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  min-height: 760px;
}

/* Left panel styles */
.compose--left {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 200px;
  border: 1px solid #e2e2e2;
}

.compose--left--top {
  padding: 20px;
}

.compose--left--list {
  padding: 10px;
  overflow-y: auto;
  max-height: 70%;
  flex: 2;
}

.compose--left--list--entry {
  display: block;
  font-size: 16px;
  border: 2px solid gray;
  border-radius: 5px;
  margin-top: 10px;
  padding: 5px 5px 5px 5px;
  cursor: pointer;
}

.compose--left--new {
  border-top: 1px solid #e2e2e2;
  margin-top: 30px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 5;
}

/* Main panel styles */
.compose--main {
  position: relative;
  flex: 3;
  padding: 20px;
  border: 1px solid #e2e2e2;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.compose--main--select-a-puzzle {
  font-size: 30px;
  align-self: center;
  text-align: center;
}

.compose--main--info {
}

.compose--main--info--title .editable-span {
  display: flex;
  align-items: center;
  justify-items: flex-start;
}

.compose--main--info--title .text {
  display: inline-block;
  padding: 1px;
  font-size: 30px;
  font-weight: 500;
  color: #333;
  text-decoration: none;
}

.compose--main--info--title i {
  margin-left: 2px;
}

.compose--main--info--title .on .text {
  min-width: 20px;
  width: auto;
}

.compose--main--info--title .off .text {
  padding: 3px;
  margin-right: 2px;
}

.compose--main--info--subtitle .text {
  padding: 1px;
  font-size: 16px;
  font-weight: 500;
  color: #333;
  text-decoration: none;
}

.compose--main--info--subtitle .off .text {
  padding: 3px;
  font-size: 16px;
  font-weight: 500;
  color: #333;
  text-decoration: none;
}

.compose--main--editor {
}

.compose--main--options {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
}

/* Right panel styles */
.compose--right {
  flex: 1;
  border: 1px solid #e2e2e2;
}

.compose--right--top {
  padding: 20px;
}

.compose--right--bottom {
  border-top: 1px solid #e2e2e2;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.compose--right--bottom .button {
  cursor: pointer;
  padding: 10px;
  border: 1px solid #999;
  margin-top: 15px;
  border-radius: 5px;
  text-align: center;
}

.published--update {
  font-size: 12px;
}
