.file-uploader {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  outline: 3px solid rgba(0,0,0,0);
  outline-offset: -5px;
  transition: outline-color 0.10s ease-in-out, outline-offset 0.10s ease-in-out;
}

.file-uploader--wrapper {
  background-color: #fafafa;
  outline: 2px dashed #aaa;
  width: 100%;
  flex: 1;
}

.file-uploader--box {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
}

.file-uploader--box--icon {
  width: 72px;
  height: 72px;
  fill: var(--main-blue);
  margin: 24px;
}

.file-uploader--wrapper.v2 {
  background-color: white;
}
