.battle {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  min-height: 760px;
}

.battle--main {
  position: relative;
  flex: 3;
  padding: 20px;
  border: 1px solid #e2e2e2;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.battle--selector {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.battle--buttons {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.battle--button {
  cursor: pointer;
  border-radius: 5px;
  padding: 10px;
  border: 1px solid var(--main-blue);

  justify-self: center;
  margin-left: 20px;
  margin-right: 20px;

  font-weight: bold;
  font-size: 75px;
  color: var(--main-blue);
}

.mobile .battle--button {
  font-weight: bold;
  font-size: 25px;
}

.battle--button.disabled {
  color: #d9d9d9;
}

.battle--name {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.battle--input {
  padding: 10px;

  justify-self: center;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;

  font-weight: bold;
  font-size: 30px;
}

.battle--teams {
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 20px;
}

.battle--team {
  display: flex;
  justify-content: top;
  flex-direction: column;
  margin-left: 20px;
  margin-right: 20px;
}

.battle--player {
  margin-top: 20px;
  font-size: 20px;
}

.battle--team-name {
  font-size: 20px;
  font-weight: bold;
}
