.replay {
  height: 100%;
  width: 100%;
  min-width: min-content;
  position: relative;
}

.replay .grid-controls .grid--content .blurable {
  opacity: 1;
}

.replay .grid-controls .grid--cover {
  display: none;
}

.replay .scrub {
  cursor: pointer;
  user-select: none;
}

.replay .scrub.active {
  color: #4d4dff;
  font-weight: bold;
}

.replay .header--title {
  font-size: 30px;
  font-weight: 500;
  color: #333;
  text-decoration: none;
}

.replay .header--subtitle {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
}

.replay--control-icons {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  font-size: 35pt;
  color: var(--main-gray-1);
}

.scrub--speeds {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.scrub--speed--option {
  font-size: 25pt;
  cursor: pointer;
  font-weight: bold;
  color: var(--main-gray-1);
  margin-left: 10px;
}
.scrub--speed--option.selected {
  color: var(--main-blue);
}

.scrub--autoplay {
  cursor: pointer;
  color: var(--main-blue);
  display: flex;
}