.clues {
  position: relative;
  display: flex;
  padding: 0px 20px 0px 20px;
}

.clues--secret {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
  height: 19px;
  width: 100%;
}

.clues--list {
  width: 200px;
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  padding-right: 10px;
}

.clues--list--title {
  font-weight: bold;
  font-size: 14px;
}

.clues--list--scroll {
  position: relative;
  flex-basis: 570px;
  flex-grow: 1;
  overflow-y: auto;
  border-top: 1px solid #e2e2e2;
  margin-top: 5px;
  padding-right: 10px;
}

.clues--list--scroll--clue {
  display: flex;
  flex-direction: row;
  padding-top: 5px;
  padding-bottom: 5px;
  border-left: 5px solid transparent;
  cursor: pointer;
}

.clues--list--scroll--clue.complete {
  color: #888;
}

.clues--list--scroll--clue.selected {
  background-color: var(--main-blue-3);
}

.clues--list--scroll--clue.half-selected {
  border-left: 5px solid var(--main-blue-3);
}

.clues--list--scroll--clue--number {
  margin-left: 5px;
  font-weight: 400;
  line-height: 19px;
  font-weight: bold;
}

.clues--list--scroll--clue--text {
  margin-left: 10px;
}

.clues--list--scroll--clue--hint {
  font-size: 80%;
  vertical-align: middle;
}
