.movement-arrow {
  display: inline-block;
  padding: 5px;
  /* border-radius 5px; */
  border: 1px solid black;
  text-transform: uppercase;
  outline: none;
}

.mobile-grid-controls {
  flex: 1;
  display: flex;
  flex-direction: column;
  outline: none;
  position: relative;
}

.mobile-grid-controls--grid-content {
  padding: 10px;
  overflow: hidden;
}

.mobile-grid-controls--zoom-container {
  overflow: hidden;
}

.mobile-grid-controls--zoom-content {
  transform-origin: top left;
}

.mobile-grid-controls--intra-clue {
  background-color: var(--main-blue-3);
  width: 24px;
  height: 24px;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-grid-controls--clue-bar {
  background-color: var(--main-blue-3);
  display: flex;
  font-size: 15px;
  flex: 1;
  min-height: 20px;
  position: relative;
  overflow: hidden;
}

.mobile-grid-controls--clue-bar.touching {
  background-image: linear-gradient(to bottom, #edffff, #dcefff);
}

.mobile-grid-controls--clue-bar--number {
  font-weight: bold;
  display: inline-block;
}

.mobile-grid-controls--clue-bar--text {
  margin-left: 20px;
  display: inline-block;
  overflow-y: auto;
  max-height: 100%;
}

.mobile-grid-controls--clue-bar--text > span {
  overflow-y: auto;
}

.mobile-grid-controls--clue-bar--clues--container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
}

.mobile-grid-controls--clue-bar--main,
.mobile-grid-controls--clue-bar--preview {
  flex: 1;
  min-height: 1px;
  display: flex;
  align-items: center;
}
