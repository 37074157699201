.simple-keyboard {
  user-select: none;
}

.simple-keyboard .hg-button {
  width: 20px;
}

.hg-button span {
  pointer-events: none;
}

.simple-keyboard .hg-button.hg-button-del,
.simple-keyboard .hg-button.hg-button-abc,
.simple-keyboard .hg-button.hg-button-more {
  color: white;
  background-color: #888;
  width: 40px;
}
.simple-keyboard .hg-button.hg-button-x {
  width: 30px;
  opacity: 0;
}

.simple-keyboard .hg-button.hg-button-emoji {
  width: 40px;
  padding: 5px;
}

.simple-keyboard .hg-button.hg-button-emoji span {
  width: 100%;
  height: 100%;
  background-image: url(https://scontent-sjc3-1.xx.fbcdn.net/v/t39.1997-6/p200x200/851575_392309627533016_444569512_n.png?_nc_cat=1&_nc_ht=scontent-sjc3-1.xx&oh=a0a2b47e4baffc248516c9bf6ba0015d&oe=5CD29CB4);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.simple-keyboard .hg-button.hg-button-emoji::after {
}

.simple-keyboard .hg-button.hg-button-space {
  color: white;
  width: 180px;
}

.simple-keyboard .hg-button.hg-button-rebus {
  color: white;
  background-color: var(--main-blue);
  width: 40px;
  letter-spacing: 2px;
}
