.grid {
  border-collapse: collapse;
  table-layout: fixed;
}

.grid--cell {
  position: relative;
  padding: 0px;
  margin: 0px;
  border: 1px solid #aaaaaa;
  box-sizing: border-box;
}
