.account {
  height: 100%;
  width: 100%;
  position: relative;
}

.account--left {
}

.account--right {
}
